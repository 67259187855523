import { TypedJSON } from 'typedjson';
import LiveBlogMatchModel from './live-blog-match-model';

export const modelMatchToResponseModel = (response: any): LiveBlogMatchModel => {
	response.round = response.round ? response.round.key : '';

	const serializer = new TypedJSON(LiveBlogMatchModel);
	const result = serializer.parse(response);

	if (result) {
		result.goal_home = result.score && result.score.total && result.score.total.home ? result.score.total.home : 0;
		result.goal_away = result.score && result.score.total && result.score.total.away ? result.score.total.away : 0;

		return result;
	}

	return new LiveBlogMatchModel();
};
