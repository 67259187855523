import 'reflect-metadata';
import { AnyT, jsonMember, jsonObject } from 'typedjson';
import LiveBlogTeamModel from '../../../../models/teams/live-blog-team.model';
import ScoreModel from '../../../../../../Partials/Blocky/v2/partials/matches/models/ScoreModel';
import MinuteModel from '../../../../../../Partials/Blocky/v2/partials/matches/models/MinuteModel';
import StatusModel from '../../../../../../Partials/Blocky/v2/partials/matches/models/StatusModel';

@jsonObject()
export default class LiveBlogMatchModel {
	@jsonMember(AnyT)
	public id: string | number = '';

	@jsonMember(String)
	public slug: string = '';

	@jsonMember(StatusModel)
	public status: StatusModel | null = null;

	@jsonMember(String)
	public kickoff_time: string | null = null;

	@jsonMember(String)
	public finished_at: string | null = null;

	@jsonMember(LiveBlogTeamModel)
	public home_team: LiveBlogTeamModel = new LiveBlogTeamModel();

	@jsonMember(LiveBlogTeamModel)
	public away_team: LiveBlogTeamModel = new LiveBlogTeamModel();

	@jsonMember(ScoreModel)
	public score: ScoreModel | null = null;

	@jsonMember(MinuteModel)
	public minute: MinuteModel | null = null;

	@jsonMember(AnyT)
	public group: any;

	@jsonMember(AnyT)
	public winner: any;

	@jsonMember(String)
	public round: string | null = null;

	@jsonMember(Number)
	public goal_home: number = 0;

	@jsonMember(Number)
	public goal_away: number = 0;

	@jsonMember(Boolean)
	public live_updates: boolean | null = null;

	@jsonMember(Boolean)
	public lineup_available: boolean | null = null;

	@jsonMember(String)
	public updated_at: string | null = null;
}
