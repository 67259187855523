import HttpService from '../../../../../../services/rest/HttpService';
import { modelMatchToResponseModel } from './models/live-blog-events.mapper';
import LiveBlogMatchModel from './models/live-blog-match-model';

export enum EventStatuses {
	UPCOMING = 'UPCOMING',
	NOT_STARTED = 'NOT_STARTED',
	FINISHED = 'FINISHED',
	INTERRUPTED = 'INTERRUPTED',
	LIVE = 'LIVE',
}

export type EventStatusesTypes = EventStatuses.NOT_STARTED | EventStatuses.INTERRUPTED | EventStatuses.LIVE;

export const loadMatches = (
	activeTab: EventStatusesTypes,
	tournamentFilter: string | number,
	seasonFilter: string,
	participantsIds: string[],
	entitiesLimit: number,
	setLoadedMatches: Function,
	setEntitiesLimit: Function,
	setLoading: Function,
	selectedSport: string,
	lang: string,
) => {
	const newLimit = entitiesLimit + 10;
	const dataForRequest = extractDataForFetchingFootballMatches(
		extractStatusesTypesBasedOnActiveTab(selectedSport, activeTab) as EventStatusesTypes[],
		tournamentFilter,
		seasonFilter,
		participantsIds,
		newLimit,
		lang,
	);

	if (participantsIds && participantsIds.length > 0) {
		HttpService.instanceFootball()
			.get(dataForRequest)
			.then((response: any) => {
				const responseData: Array<any> | null = (response && response.data && response.data.matches) || null;
				const remappedData = responseData && responseData.map((entity: any) => modelMatchToResponseModel(entity));
				setLoadedMatches(remappedData);
				setEntitiesLimit(newLimit);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	} else if (tournamentFilter && seasonFilter) {
		HttpService.instanceFootball()
			.get(dataForRequest)
			.then((response: any) => {
				const responseData: Array<any> | null = (response && response.data && response.data.matches) || null;
				const remappedData = responseData && responseData.map((entity: any) => modelMatchToResponseModel(entity));
				setLoadedMatches(remappedData);
				setEntitiesLimit(newLimit);
				setLoading(false);
			})
			.catch(() => setLoading(false));
	} else {
		setEntitiesLimit(0);
		setLoadedMatches(null);
		setLoading(false);
	}
};

// this method will generate url for fetching football matches
export const extractDataForFetchingFootballMatches = (
	statusTypes: EventStatusesTypes[],
	tournamentFilter: string | number,
	seasonFilter: string,
	participantsIds: string[],
	limit: number = 10,
	lang: string,
) => {
	const searchUrl = new URLSearchParams('');
	const hardcodedParams = `sort_direction=asc&offset=0&limit=${limit}&language_code=${lang}`;
	const mainUrl = '/v2/matches?';
	const statusTypesToString = statusTypes.join(',');
	const tournamentId = tournamentFilter || '';
	const seasonId = seasonFilter || '';

	if (tournamentId && tournamentId !== '') {
		searchUrl.append('tournament_ids', tournamentId.toString());
	}

	if (seasonId && seasonId !== '') {
		searchUrl.append('season_ids', seasonId.toString());
	}

	if (statusTypesToString && statusTypesToString.length > 0) {
		searchUrl.append('status_types', statusTypesToString);
	}

	if (participantsIds && participantsIds.length > 0) {
		searchUrl.append('team_ids', participantsIds.join(','));
		searchUrl.append('team_ids_operator', 'OR');
	}

	return `${mainUrl}${hardcodedParams}&${searchUrl.toString()}`;
};

export const extractStatusesTypesBasedOnActiveTab = (selectedSport: string, activeTab: EventStatusesTypes) => {
	const enumToCheck = EventStatuses;

	switch (activeTab) {
		case EventStatuses.NOT_STARTED:
			return [enumToCheck.NOT_STARTED, EventStatuses.LIVE];
		case EventStatuses.INTERRUPTED:
			return [enumToCheck.INTERRUPTED];
		default:
			return [];
	}
};

// This method is used to extract saved participants ids for specific sport
export const extractParticipantIds = (teams: any): string[] => {
	return teams && teams.length > 0 && teams.map((x: any) => x.id);
};

export const findEmptyEventArrIndex = (model: any[]) => {
	if (model) {
		return model.findIndex((modelEntity) => modelEntity.id === null && modelEntity.home_team === null && modelEntity.away_team === null);
	}
};

export const emptyEventRowAvailable = (events: any[]) => {
	const newRowIndex = findEmptyEventArrIndex(events);
	return newRowIndex && newRowIndex >= 0;
};

export const removeEventById = (events: any[], searchedEventId: number) => {
	return events.filter((event) => event.id !== searchedEventId);
};

export const findIsEventAlreadySelected = (events: any[], currentlySelectedEl: any) => {
	return events.findIndex((event: any) => event && event.id && event.id == currentlySelectedEl.id) >= 0;
};

export const filterEvents = (selectedEvents: any[], availableEvents: any[]) => {
	return availableEvents.filter((elem) => !selectedEvents.find(({ id }) => elem.id === id));
};

export const toggleGoals = (match: LiveBlogMatchModel, homeScore: number, awayScore: number) => {
	return match.status && (match.status.type === EventStatuses.LIVE || match.status.type === EventStatuses.FINISHED)
		? ` ${homeScore}:${awayScore} `
		: ` - `;
};

export const printMinutesIfMatchInProgress = (match: LiveBlogMatchModel) => {
	if (match.minute && match.minute.injuryTime && match.minute.injuryTime > 0) {
		return match.status && match.status.type === EventStatuses.LIVE ? `(${match.minute.regularTime}+${match.minute.injuryTime})` : '';
	} else {
		return match.minute && match.status && match.status.type === EventStatuses.LIVE ? `(${match.minute.regularTime})` : '';
	}
};

export const applyInProgressClass = (type: string | null) => {
	return type === EventStatuses.LIVE ? 'border-pulsate-left' : '';
};
