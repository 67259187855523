import React, { useState } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import LiveBlogModel from '../../../models/live-blog.model';
import '../live-blog-main-media/styles/style.scss';
import { SportTypes } from '../../../../../../constants/sport.types';
import { MatchesScrollbar } from '../live-blog-type-select/matches-scroll/matches-scroll';
import { EventStatuses, EventStatusesTypes, extractParticipantIds } from './live-blog-events.helper';
import LiveBlogMatchModel from './models/live-blog-match-model';
import { useTranslation } from 'react-i18next';

type Properties = {
	liveBlog: LiveBlogModel;
	setSelectedLiveBlogEvents: Function;
	selectedEvent: LiveBlogMatchModel;
	seasonId: string;
};

export const LiveBlogEventsSelectContainer: React.FunctionComponent<Properties> = ({
	selectedEvent,
	setSelectedLiveBlogEvents,
	liveBlog,
	seasonId,
}) => {
	const [activeTab, setActiveTab] = useState<EventStatusesTypes>(EventStatuses.NOT_STARTED);
	const [t] = useTranslation();

	return (
			<Row className='mb-2 live-blog-image-container'>
				<Col>
					<Nav pills className='mb-3'>
						<NavItem>
							<NavLink
								id={`tab-link-${EventStatuses.NOT_STARTED}`}
								active={activeTab === EventStatuses.NOT_STARTED}
								onClick={() => setActiveTab(EventStatuses.NOT_STARTED)}
							>
								<i className='fa fa-calendar' /> {t('upcoming')}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								id={`tab-link-${EventStatuses.INTERRUPTED}`}
								active={activeTab === EventStatuses.INTERRUPTED}
								onClick={() => setActiveTab(EventStatuses.INTERRUPTED)}
							>
								<i className='fa fa-calendar-minus-o' /> {t('interrupted')}
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={activeTab} id={`tab-pane-matches-${activeTab}`} style={{ borderStyle: 'dotted' }}>
						<TabPane tabId={activeTab} className='p-3 pb-0 bg-white'>
							{liveBlog.sport && liveBlog.sport.toLowerCase() === SportTypes.FOOTBALL && (
								<MatchesScrollbar
									liveBlog={liveBlog}
									selectedSport={liveBlog.sport}
									activeTab={activeTab}
									tournamentFilter={liveBlog.competitions && liveBlog.competitions[0] && liveBlog.competitions[0].id}
									seasonFilter={seasonId}
									participantsIds={extractParticipantIds(liveBlog.teams)}
									setSelectedLiveBlogEvents={setSelectedLiveBlogEvents}
									selectedEvent={selectedEvent}
								/>
							)}
						</TabPane>
					</TabContent>
				</Col>
			</Row>
	);
};
