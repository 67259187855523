import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, FormGroup } from 'reactstrap';
import Scrollbar from 'react-custom-scrollbars';
import { EventStatusesTypes, filterEvents, loadMatches } from '../../live-blog-events/live-blog-events.helper';
import LiveBlogModel from '../../../../models/live-blog.model';
import { MatchRow } from '../match-row/match-row';
import LiveBlogMatchModel from '../../live-blog-events/models/live-blog-match-model';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../../store/store';

type Properties = {
	liveBlog: LiveBlogModel;
	activeTab: EventStatusesTypes;
	participantsIds: string[];
	tournamentFilter: string | number;
	seasonFilter: string;
	selectedSport: string;
	setSelectedLiveBlogEvents: Function;
	selectedEvent: any;
};

export const MatchesScrollbar: FunctionComponent<Properties> = ({
	liveBlog,
	activeTab,
	participantsIds,
	tournamentFilter,
	seasonFilter,
	selectedSport,
	setSelectedLiveBlogEvents,
	selectedEvent,
}) => {
	const [t] = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const [entitiesLimit, setEntitiesLimit] = useState<number>(0);
	const [loadedMatches, setLoadedMatches] = useState<LiveBlogMatchModel[] | null>(null);
	const areMatchesAvailable = !!loadedMatches && loadedMatches.length > 0;
	const language = useSelector((state: AppState) => state.project.currentProject.language);

	let loadedMatchesWithoutSelected = areMatchesAvailable ? loadedMatches && filterEvents(liveBlog.sport_events, loadedMatches) : [];

	useEffect(() => {
		if (seasonFilter) {
			resetEntitiesLimitAndLoadData();
		} else if (!seasonFilter) {
			setLoadedMatches([]);
			resetEntitiesLimitAndLoadData();
		}
	}, [participantsIds && participantsIds.length, activeTab, seasonFilter]);

	const loadMore = (resetLimit: boolean = false) => {
		setLoading(true);
		loadMatches(
			activeTab,
			tournamentFilter,
			seasonFilter,
			participantsIds,
			resetLimit ? 0 : entitiesLimit,
			setLoadedMatches,
			setEntitiesLimit,
			setLoading,
			selectedSport,
			language,
		);
	};

	const resetEntitiesLimitAndLoadData = () => {
		setEntitiesLimit(0);
		loadMore(true);
	};

	const onEventSelect = (events: LiveBlogMatchModel) => {
		setSelectedLiveBlogEvents(events);
	};

	const selectedEventId = selectedEvent && selectedEvent.id;
	return (
		<FormGroup>
			<Scrollbar style={{ height: areMatchesAvailable ? 500 : 100 }} className={loading ? 'loading-overlay' : ''}>
				{areMatchesAvailable ? (
					<>
						{loadedMatchesWithoutSelected &&
							loadedMatchesWithoutSelected.map((event: LiveBlogMatchModel) => (
								<MatchRow match={event} key={`event-row-${event.id}`} selectedMatchId={selectedEventId} updateSelectedEvents={onEventSelect} />
							))}
						<div className='d-flex justify-content-center'>
							{loadedMatches && entitiesLimit === loadedMatches.length && (
								<Button id='load-more-matches-button' color='primary' outline className='w-80 mt-3 mb-2' onClick={() => loadMore()}>
									{t('load_more')}
								</Button>
							)}
						</div>
					</>
				) : (
					<div className='text-center p-3'>{t('no_matches_found')}</div>
				)}
			</Scrollbar>
		</FormGroup>
	);
};
