import React, { FunctionComponent } from 'react';
import { Label, Button } from 'reactstrap';
import moment from 'moment';
import './style/match-row.style.scss';
import LiveBlogMatchModel from '../../live-blog-events/models/live-blog-match-model';
import { applyInProgressClass, printMinutesIfMatchInProgress, toggleGoals } from '../../live-blog-events/live-blog-events.helper';

export type Properties = {
	match: LiveBlogMatchModel;
	selectedMatchId: number | null;
	updateSelectedEvents: Function;
};
export const MatchRow: FunctionComponent<Properties> = ({ match, updateSelectedEvents, selectedMatchId }) => {
	const homeScore = match.score && match.score.total && match.score.total.home ? match.score.total.home : 0;
	const awayScore = match.score && match.score.total && match.score.total.away ? match.score.total.away : 0;

	if (!match) return null;
	const liClass = `list-group-item d-flex align-items-center ${selectedMatchId === match.id && 'selected-match-row'} ${
		match.finished_at !== '' && match.status && applyInProgressClass(match.status.type)
	}`;

	const onMatchSelect = (selectedMatch: LiveBlogMatchModel) => {
		updateSelectedEvents(selectedMatch);
	};

	return (
		<li className={liClass} id={`match-line-tags-${match.id}`}>
			<Button className='btn btn-light btn-sm mr-2' id={`add-match-line-button-${match.id}`} onClick={() => onMatchSelect(match)}>
				{selectedMatchId === match.id ? <i className='fa fa-check' /> : <i className='fa fa-plus' />}
			</Button>
			{match.finished_at ? (
				<Label className='form-check-label'>
					<strong>{`${moment(match.finished_at).format('DD MMM YYYY')} `}</strong>
					<span>{` - ${match.home_team.name}`}</span>
					{` ${homeScore}:${awayScore} `}
					<span>{match.away_team.name}</span>
				</Label>
			) : (
				<Label className='form-check-label'>
					<strong>{`${moment(match.kickoff_time ? match.kickoff_time : '').format('DD MMM YYYY')} `}</strong>
					<span>{` - ${match.home_team.name}`}</span>
					{toggleGoals(match, homeScore, awayScore)}
					<span>{match.away_team.name}</span>
					{match && printMinutesIfMatchInProgress(match)}
				</Label>
			)}
		</li>
	);
};
